import React from 'react'
import { Section, SEO } from '../components'
import Layout from '../layouts'

export default function TermsPage() {
  return (
    <Layout>
      <SEO title="Terms & Conditions" description="Pure Func terms & conditions" pathname="/terms" />
      <Section>
        <>
          <h1>Terms & Conditions</h1>
        </>
      </Section>
    </Layout>
  )
}
